<template>
  <div class="photos">
    <div class="md-layout">
      <div 
        class="md-layout-item md-size-50"
        v-for="photo in photos" 
        :key="photo.id">
        <md-card class="image-card">
          <md-card-media-cover>
            <md-card-media md-ratio="16:9">
              <img v-bind:src="photo.image">
              <!-- <img src="../../public/test1.jpg"> -->
            </md-card-media>
          </md-card-media-cover>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import test1 from "../../public/test1.jpg"
import test2 from "../../public/test2.jpg"
import test3 from "../../public/test3.jpg"
import test4 from "../../public/test4.jpg"

export default {
  name: 'Photos',
  data: () => ({
    photos: [
      {
        id: "1",
        image: test1
      },
      {
        id: "2",
        image: test2
      },
      {
        id: "3",
        image: test3
      },
      {
        id: "4",
        image: test4
      }
    ]
  }),
  created() {
    for (let i = 0; i < 100; i++) {
      this.content += "Test"
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/_shared.scss';

.image-card {
  margin: inherit;
}

</style>